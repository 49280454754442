#forthContener {
    height: 826px;
    width: 100%;
    background-color: #E8E8E8;
}

#About {
    text-align: center;
    padding: 45px 0px;
    color: #081160;
}

.content {
    width: 737px;
    height: 95px;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    margin: auto;
text-align: center;
}


.content span{
    color: #0A8F9F;
}

#main{
display: flex;
justify-content: center;
align-items: center;
gap: 44px;
margin-top: 74px;
}
#leftContener{
    width: 625px;
    height: 417px;
    background-image: url(../img/aboutus.svg);
}
#rightContener{
    width: 468px;
    height: 417px;

}
.paragraph{
    padding: 0px 0px 50px;
    width: 468px;
}

#btn{
    padding: 12px 18px;
    color: #fff;
    background-color: #081160;
    border-radius: 30px;
     margin: 32px 0px ; 
    text-align: center;
    text-decoration: none;

}
#btn:hover{
    animation-timing-function: ease-out;
    animation-duration: 50ms;
}
#btn:hover::before{
    content: " ";
}
#btn:hover span{
    display: none;
}
#btn:hover::after{
  content: "Learn More";
}
@media(min-width:1000px) and (max-width:1250px){

}
