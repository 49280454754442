/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

} */

#Back-img {
    background-image: url(../img/btb_landingpage.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 847px;
    width: 100%;
    position:relative;
    /* top: -64px;
    left: 0; */

}

.pera {
    padding: 223px 0px 0px 214px;
    font-size: 20px;
    text-transform: capitalize;
}

#contener {
    width: 751px;
    height: 221px;
    margin: 0px 0px 0px 214px;
    /* font-size: 48px; */
}

#we-make {
    /* padding: 0px 0px 0px 214px; */
    text-transform: capitalize;
    font-size: 48px;
}

#your-buniness {
    text-align: center;
    font-size: 45px;
    background-color: #0A8F9F;
    ;
    color: #fff;
    width: 340px;

    margin: inherit;
    padding: 2px 13px;
    border-radius: 9px;
}

#connect {
    padding: 12px 18px;
    color: #fff;
    background-color: #081160;
    border-radius: 30px;
    margin: 71px 0px 0px 296px;
    cursor: pointer;
    transition: 1s;
}


#connect:hover span{
    display: none;
}

#connect:hover:before {
    content: '';
    /* transform: rotate(45deg); */


}

#connect:hover::after {
    content: 'Let`s Connect';
   
    
}