@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto';
}

body{
  font-family: Arial, Helvetica, sans-serif,'roboto';
}
.mainContener{
 
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}


#Item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
  position: absolute;
}
#btblogo{
  background-image: url(../img/btb_logo.svg);
  width: 124px;
  height: 68px;
  margin-top: 12px;
  margin-left: 22px;
}
#NavItem{
  display: flex;
  justify-content: end;
  align-items: center;
  
  width: 750px;
  height: 19px;
  gap: 110px;
  margin-right: 158px;
  

}
.nav{
  text-decoration: none;
  color: #333;
  
}
@media(min-width:1000px) and (max-width:1250px){
#btbIcon{
  margin-left: 50px;
}
#NavItem{
  gap: 80px;
}

}
