#footer{
    /* width: 100%; */
    height: 356px;
    background-color: #081160;
}
#logo-part{
   
    /* background-size: cover; */
    width: 134px;
    height: 134px;
    background-color: #fff;
    border-radius: 50%;
    float: left;
    margin: 23px 0px 0px 62px;
}

#logo-img{
    width: 106px;
    height: 52px;
    background-image: url(../img/btb_logo.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

#right-Contener{
    width: 650px;
    height: 300px;
    /* border: 2px solid white; */
    float: right;
    margin: 13px 130px;
    display: flex;
    justify-content: center;
    align-items: start;
    color: #fff;
   

}
.footer-content{
    width: 217px;
}
.footer-content h2:hover{
    text-transform: uppercase;
    border-left: 3px solid #fff;
    padding-left: 7px;
}

.deta{
    margin: 16px 0px 0px 0px;
    text-transform: capitalize;
}
.deta:hover{
    /* text-transform: uppercase; */
    border-left:3px solid #fff ;
    cursor: pointer;
    padding-left: 7px;

}
#logos{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    margin-top: 14px;
}
.logo-imgs{
    width: 24px;
    height: 24px;
    font-size: 24px;
}
.lastLine{
    height: 72px;
    color: #fff;
    background-color: #09136B;
    justify-content: normal;
    padding-top: 20px;
    padding-left: 40px;
    text-align: center;
}