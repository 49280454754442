/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

#Forth {
    /* width: 616px; */
    height: 834px;
}

#get-con {
    margin: 29px;
    text-align: center;
}

#left-cont {
    background-image: url(../img/contactus.svg);
    width: 490px;
    height: 507px;
    margin: 75px 90px 0px;
    float: left;
}

#right-cont {
    width: 616px;
    height: 630px;
    border: 0.2px solid #333;
    float: right;
    padding: 0px 0px 0px 20px;
    font-size: 16px;
    filter: blur(0.5px);
    border-radius: 10px;
    margin-right: 130px;

}

.First {
    width: 570px;
    height: 52px;
    color: #333;
    border-radius: 5px;
    border: 0.4px solid #333;
    background: #F9F9F9;
    padding: 0px 0px 0px 20px;
    margin: 71px auto 0px auto;
   
}

.marg {
    margin: 32px auto 0px;
}
#Massage{
width: 570px;
height: 150px;
border-radius: 5px;
border: 0.4px solid #333;
background: #F9F9F9;
margin-top: 38px;
font-size: 16px;
padding: 7px 0px 0px 22px;
}

#sub-btn{
    width: 472px;
    color: #333;
    border: 3px solid #081160;
    border-radius: 30px;
    padding: 12px;
    font-size: 24px;
    background-color: #fff;
    font-weight: 500;
    margin: 32px 50px;
    transition: 1s;
}
#sub-btn:hover{
    cursor: pointer;
    color: #fff;
    background-color: #081160;
    border: px solid #fff;
    transform:translate(50px,);
}