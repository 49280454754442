@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#third-continer {
    height: 831px;
    width: 100%;
    background-color: #fff;
}

.our-service {
    text-align: center;
    padding-top: 35px;
    font-size: 24px;
    font-weight: 600;
    color: #081160;
}

.Best-service {
    text-align: center;
    text-transform: uppercase;
    font-size: 48px;
    width: 815px;
    height: 112px;
    font-family: 'Roboto';
    margin: 15px auto;
}

#third-continer .Best-service span {
    color: #0A8F9F;
}

.continer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 42px;
    margin-top: 90px;
}

.main {
    width: 353px;
    height: 437px;
    /* background-color: #081160; */
    border-radius: 15px;
    border: 1px solid #4b4b4b;
    opacity: 0.8;
    transition: 1s;
}

.main:hover {
    box-shadow: 0px 10px rgb(240, 240, 240);
    transform: scale(1.1);
    z-index: 2;
}

.man {
    width: 240px;
    height: 240px;
    margin: 18px auto;
}

.img1 {
    background-image: url(../img/btb_softwaredev.svg);
}

.img2 {
    background-image: url(../img/btbweb.svg);
}

.img3 {
    background-image: url(../img/btbitservice.svg);
}

.heading {
    width: 231px;
    height: 72px;
    color: #081160;
    text-align: center;
    font-size: 24px;
    margin: auto;
}

.heading3 {
    width: 224px;
    height: 72px;
    color: #081160;
    text-align: center;
    font-size: 24px;
    margin: auto;
}

.pera1 {
    width: 354px;
    height: 60px;
    color: #222;
    text-align: center;
    text-transform: capitalize;
    margin: 15px auto;
}

@media(min-width:1000px) and (max-width:1250px) {
    .main {
        width: 20%;
    }
    .man{
        width: 200px;
    }
}